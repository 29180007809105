export default function loggedInUser() {
  const userName = (user) => {
    const { firstName, lastName, name } = user

    if (name != null && name !== '') {
      return name
    } else if (
      firstName != null &&
      firstName !== '' &&
      lastName != null &&
      lastName !== ''
    ) {
      return firstName + ' ' + lastName
    } else if (firstName != null && firstName !== '') {
      return firstName
    } else {
      return 'Asana Rebel User'
    }
  }

  const userAuthToken = (user) => {
    return user.authToken
  }

  return {
    userName,
    userAuthToken,
  }
}
