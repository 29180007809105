import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useTranslation } from 'react-i18next'

export default function DiscountModal({ onClose }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })
  const router = useRouter()
  const { publicRuntimeConfig } = getConfig()

  const { discount, price, currency, coupon, plan } = router.query

  const discountedPrice = () =>
    Math.trunc((parseInt(price) * parseInt(discount)) / 100) / 100

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 transition-opacity"
        aria-hidden="true"
        onClick={onClose}>
        <div className="absolute inset-0 bg-gray-800 opacity-75" />
      </div>
      <div className="flex min-h-screen items-center justify-center px-8 text-center sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block w-full transform overflow-hidden rounded-2xl bg-ar-white text-left shadow-2xl transition-all sm:my-8 sm:align-middle md:w-2/5 2xl:w-1/4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="mx-auto flex w-full flex-col space-y-8 p-10 text-center">
            <p className="mt-4 font-link text-2xl text-ar-dark-gray">
              {t('discount.welcome_offer')}
            </p>
            <p className="mx-auto w-11/12 font-link text-4xl text-ar-dark-gray xl:w-1/2">
              {t('discount.percent_off', { discount })}
            </p>
            <p className="font-link text-4xl text-ar-dark-green">
              <span className="mr-6 line-through">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: currency,
                }).format(parseInt(price) / 100)}
              </span>
              <span>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: currency,
                }).format(discountedPrice())}
              </span>
            </p>
            <button
              type="button"
              onClick={() =>
                router.push({
                  pathname: `${publicRuntimeConfig.webFunnelUrl}/checkout`,
                  query: {
                    discount_coupon: coupon,
                    plan_id: plan,
                  },
                })
              }
              className="m-auto block w-full rounded-xl bg-ar-dark-green p-5 text-center font-link text-lg uppercase text-white focus:outline-none lg:w-5/6 lg:p-4 xl:w-1/2">
              {t('discount.get_discount')}
            </button>
          </div>
          <span
            className="absolute top-0 right-1 m-5 cursor-pointer font-bold text-ar-dark-gray hover:opacity-75"
            onClick={onClose}>
            X
          </span>
        </div>
      </div>
    </div>
  )
}

DiscountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
