import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import DailyWorkout from '../components/DailyWorkout'
import LazyGraphQL from '../components/LazyGraphQL'
import TopPicks from '../components/TopPicks'
import MyFavoriteWorkouts from '../components/MyFavoriteWorkouts'
import MyFavoriteCollections from '../components/MyFavoriteCollections'
import ActiveChallenges from '../components/ActiveChallenges'
import RecentlyCompleted from '../components/RecentlyCompleted'
import HeaderLayout from '../components/Layout/Header'
import DiscountModal from '../components/Modal/Discount'

export default function Home() {
  const router = useRouter()
  const [showDiscount, setShowDiscount] = useState(false)

  useEffect(() => {
    if (router.query.discount) {
      setShowDiscount(true)
    }
  }, [router])

  return (
    <>
      {showDiscount && <DiscountModal onClose={() => setShowDiscount(false)} />}
      <div className="hidden md:block">
        <DailyWorkout />
      </div>

      <div className="container mx-auto flex flex-col px-4 pb-28 md:space-y-6">
        <LazyGraphQL>
          <TopPicks />
        </LazyGraphQL>

        <LazyGraphQL>
          <MyFavoriteWorkouts />
        </LazyGraphQL>

        <LazyGraphQL>
          <MyFavoriteCollections />
        </LazyGraphQL>

        <LazyGraphQL>
          <ActiveChallenges />
        </LazyGraphQL>

        <LazyGraphQL>
          <RecentlyCompleted />
        </LazyGraphQL>
      </div>
    </>
  )
}

Home.getLayout = function getLayout(page) {
  return <HeaderLayout>{page}</HeaderLayout>
}
