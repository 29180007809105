import { gql } from '@apollo/client'

export const WORKOUTS_DAILY_RANDOM_QUERY = gql`
  query workoutsDailyRandom {
    workoutsDailyRandom {
      id
      free
      title
      duration
      intensity
      listed
      image {
        landscape
      }
      trainer {
        name
      }
    }
  }
`
