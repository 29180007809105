import { gql } from '@apollo/client'

export const ACTIVE_CHALLENGES_QUERY = gql`
  query activeChallenges {
    activeChallenges {
      id
      title
      description
      webUserChallengeCurrentDay
      workoutsCount
      image {
        square
      }
    }
  }
`
