import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'
import PlayButton from '../../public/icons/play-button.svg'
import EmptyHeart from '../../public/icons/heart-empty.svg'
import FilledHeart from '../../public/icons/heart-filled.svg'
import useApi from '../../hooks/useApi'
import { DAILY_WORKOUT_QUERY } from '../../graphql/queries/dailyWorkout'
import { useUser } from '../../context/UserContext'

export default function DailyWorkout() {
  const { publicRuntimeConfig } = getConfig()
  const [user] = useUser()

  const { subscription } = user

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const [favorite, setFavorite] = useState()
  const { addToFavorites, removeFromFavorites } = useApi()
  const router = useRouter()

  const { data, loading, error } = useQuery(DAILY_WORKOUT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ dailyWorkout }) => setFavorite(dailyWorkout.listed),
  })

  if (loading) return null

  if (error) return <p>ERROR: {error.message}</p>

  const { dailyWorkout } = data

  const createFavorite = async (listableType, listableId) => {
    try {
      const listItem = await addToFavorites({
        listableType,
        listableId,
      })

      if (listItem.id !== undefined) {
        setFavorite(true)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const removeFavorite = async (listableType, listableId) => {
    try {
      const listItem = await removeFromFavorites({
        listableType,
        listableId,
      })

      if (listItem.id !== undefined) {
        setFavorite(false)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const onClick = () => {
    if (dailyWorkout.free || subscription) {
      router.push({
        pathname: '/watch/[wid]',
        query: { wid: dailyWorkout.id, source: 'explore' },
      })
    } else {
      router.push(`${publicRuntimeConfig.webFunnelUrl}/plans`)
    }
  }

  const dataProps = {
    'data-tracking-event-type': 'startClick',
    'data-tracking-element-type': 'featuredBanner',
    'data-tracking-element-name': 'workout',
    'data-tracking-content-id': dailyWorkout.id,
  }

  return (
    <div className="relative">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="h-120 w-full object-cover">
        <source src={dailyWorkout.trailer.landscape.url} type="video/mp4" />
      </video>
      <div className="absolute top-0 right-0 h-full w-full bg-gradient-to-r from-gray-900 opacity-50"></div>
      <div className="container mx-auto px-4">
        <div className="absolute top-1/2 md:top-1/3">
          <div className="text-6xl font-black text-white">
            {dailyWorkout.title}
          </div>
          <div className="mt-4 w-4/6 text-white">
            {dailyWorkout.description}
          </div>
          <div className="mt-8 flex">
            <button
              {...dataProps}
              className="flex h-10 cursor-pointer rounded bg-ar-light-green px-5 font-bold text-white hover:opacity-80"
              onClick={onClick}>
              <PlayButton className="mt-2.5" />
              <span className="mt-2 ml-3">{t('widgets.play')}</span>
            </button>

            <span className="ml-5">
              <button
                className="flex h-10 cursor-pointer rounded bg-ar-transparent px-5 font-bold text-white hover:opacity-80"
                onClick={() =>
                  favorite
                    ? removeFavorite('V2::Workout', dailyWorkout.id)
                    : createFavorite('V2::Workout', dailyWorkout.id)
                }>
                {favorite ? (
                  <FilledHeart className="mt-2 cursor-pointer" />
                ) : (
                  <EmptyHeart className="mt-2 cursor-pointer" />
                )}
                <span className="mt-2 ml-3">{`${
                  favorite
                    ? t('widgets.remove_from_favorites')
                    : t('widgets.add_to_favorites')
                }`}</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
