import { gql } from '@apollo/client'

export const DAILY_WORKOUT_QUERY = gql`
  query DailyWorkout {
    dailyWorkout {
      id
      free
      title
      listed
      description
      trailer {
        landscape
      }
    }
  }
`
