import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'

export default function ActiveChallengeWidget({ challenge, dataProps }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const router = useRouter()
  const src = challenge.image.square

  const challengeStarted = () => challenge.webUserChallengeCurrentDay !== null

  const completedPercent = () => {
    return `${Math.round(
      ((challenge.webUserChallengeCurrentDay || 0) / challenge.workoutsCount) *
        100,
    )}%`
  }

  const daysLeft = () =>
    challenge.workoutsCount - (challenge.webUserChallengeCurrentDay - 1 || 0)

  const redirectToSingleView = () => {
    router.push({
      pathname: `/challenges/${challenge.id}`,
    })
  }

  return (
    <div
      {...dataProps}
      className="mx-1 flex h-full cursor-pointer flex-col justify-start p-2 transition-transform sm:hover:scale-105 lg:hover:scale-105 xl:hover:scale-105 2xl:hover:scale-105"
      onClick={() => redirectToSingleView()}>
      <div className="relative mb-2 rounded-xl">
        <Image
          src={src}
          alt="Challenge cover picture"
          width="288"
          height="288"
          className="rounded-xl"
        />
      </div>

      {challengeStarted() ? (
        <div className="mx-auto mt-3 flex w-11/12 flex-col space-y-2">
          <div className="flex h-[20px] overflow-hidden rounded-full bg-[#EFEFEF]">
            <div
              style={{ width: completedPercent() }}
              className="rounded-full bg-gradient-to-r from-[#32F299] to-[#41CD8C]"
            />
          </div>
          <div className="m-auto flex w-11/12 justify-between font-link text-xs uppercase text-ar-dark-gray">
            <p>
              {t('widgets.day', {
                count: challenge.webUserChallengeCurrentDay - 1 || 0,
              })}
            </p>
            <p>{t('widgets.days_left', { count: daysLeft() })}</p>
          </div>
        </div>
      ) : (
        <>
          <p className="truncate font-link text-base text-ar-dark-gray">
            {challenge.title}
          </p>
          <p className="text-md w-3/4 font-body text-ar-light-gray text-opacity-60">
            {challenge.description}
          </p>
        </>
      )}
    </div>
  )
}

ActiveChallengeWidget.propTypes = {
  challenge: PropTypes.object.isRequired,
  dataProps: PropTypes.object,
}
