import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import RowContent from '../RowContent'
import RowHeading from '../RowHeading'
import { ACTIVE_CHALLENGES_QUERY } from '../../graphql/queries/activeChallenges'
import ActiveChallengeWidget from '../widgets/ActiveChallenge'
import CollectionSkeleton from '../CollectionSkeleton'

export default function ActiveChallenges() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { data, loading, error } = useQuery(ACTIVE_CHALLENGES_QUERY)

  const renderHeading = () => (
    <RowHeading>{t('row_headers.active_challenges')}</RowHeading>
  )

  const dataProps = (challengeId) => {
    return {
      'data-tracking-event-type': 'navigationClick',
      'data-tracking-element-type': 'challengeDiscovery',
      'data-tracking-element-name': 'active_challenges',
      'data-tracking-content-type': 'challenge',
      'data-tracking-content-id': challengeId,
    }
  }

  if (loading) {
    return (
      <>
        {renderHeading()}
        <CollectionSkeleton />
      </>
    )
  }

  if (error) return <p>ERROR: {error.message}</p>

  const challenges = data.activeChallenges
  const contentPresent = () => challenges?.length > 0

  if (!contentPresent()) {
    return <></>
  } else {
    return (
      <>
        {renderHeading()}
        <RowContent>
          {challenges.map((challenge) => (
            <ActiveChallengeWidget
              {...dataProps(challenge.id)}
              challenge={challenge}
              key={challenge.id}
              dataProps={dataProps(challenge.id)}
            />
          ))}
        </RowContent>
      </>
    )
  }
}
