import { gql } from '@apollo/client'

export const RECENTLY_COMPLETED_QUERY = gql`
  query recentlyCompleted {
    recentlyCompleted {
      id
      title
      duration
      intensity
      free
      listed
      image {
        landscape
      }
      trainer {
        name
      }
    }
  }
`
