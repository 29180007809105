import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import RowContent from '../RowContent'
import RowHeading from '../RowHeading'
import { WORKOUTS_DAILY_RANDOM_QUERY } from '../../graphql/queries/workoutsDailyRandom'
import WorkoutWidget from '../widgets/Workout'
import { useUser } from '../../context/UserContext'
import loggedInUser from '../../hooks/loggedInUser'
import WorkoutSkeleton from '../WorkoutSkeleton'

export default function TopPicks() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const [user] = useUser()
  const { userName } = loggedInUser()

  const { data, loading, error } = useQuery(WORKOUTS_DAILY_RANDOM_QUERY)

  const loggedInUserName = () => {
    return userName(user)
  }

  const dataProps = (workoutId) => {
    return {
      'data-tracking-event-type': 'startClick',
      'data-tracking-element-type': 'workoutDiscovery',
      'data-tracking-element-name': 'top_picks',
      'data-tracking-content-type': 'workout',
      'data-tracking-content-id': workoutId,
    }
  }

  const renderHeading = () => (
    <RowHeading>
      {t('row_headers.top_picks', { user: loggedInUserName() })}
    </RowHeading>
  )

  if (loading) {
    return (
      <>
        {renderHeading()}
        <WorkoutSkeleton />
      </>
    )
  }

  if (error) return <p>ERROR: {error.message}</p>

  const workoutsDailyRandom = data.workoutsDailyRandom

  return (
    <>
      {renderHeading()}
      <RowContent>
        {workoutsDailyRandom.map((workout) => {
          if (workout.image.landscape) {
            return (
              <WorkoutWidget
                workout={workout}
                key={workout.id}
                dataProps={dataProps(workout.id)}
                playbackSource="explore"
              />
            )
          }
        })}
      </RowContent>
    </>
  )
}
