import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { RECENTLY_COMPLETED_QUERY } from '../../graphql/queries/recentlyCompleted'
import RowContent from '../RowContent'
import RowHeading from '../RowHeading'
import WorkoutWidget from '../widgets/Workout'
import WorkoutSkeleton from '../WorkoutSkeleton'

const REDIRECT_PATH = '/exercise/recently-completed'

export default function RecentlyCompleted() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { data, loading, error } = useQuery(RECENTLY_COMPLETED_QUERY)

  const dataProps = (workoutId) => {
    return {
      'data-tracking-event-type': 'startClick',
      'data-tracking-element-type': 'workoutDiscovery',
      'data-tracking-element-name': 'recently_completed_workouts',
      'data-tracking-content-type': 'workout',
      'data-tracking-content-id': workoutId,
    }
  }

  const renderHeading = () => (
    <RowHeading
      redirectPath={REDIRECT_PATH}
      contentLength={recentlyCompleted?.length}>
      {t('row_headers.recently_completed')}
    </RowHeading>
  )

  if (loading) {
    return (
      <>
        {renderHeading()}
        <WorkoutSkeleton />
      </>
    )
  }

  if (error) return <p>ERROR: {error.message}</p>

  const recentlyCompleted = data.recentlyCompleted

  const contentPresent = () => recentlyCompleted?.length > 0

  if (!contentPresent()) {
    return <></>
  } else {
    return (
      <>
        {renderHeading()}
        <RowContent>
          {recentlyCompleted.map((workout) => {
            if (workout?.image?.landscape) {
              return (
                <WorkoutWidget
                  workout={workout}
                  key={workout.id}
                  playbackSource="explore"
                  dataProps={dataProps(workout.id)}
                />
              )
            }
          })}
        </RowContent>
      </>
    )
  }
}
